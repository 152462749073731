<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa' && rol != 'admin-sucursal'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>    
        <v-container v-else xl fluid>
            <v-row>
                <v-overlay :value="overlay" z-index="999">
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p class="mt-5">{{fraseOverlay}}</p>
                    </div>
                </v-overlay>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tablaDescargas"
                        :tableName="'Descargar Contrato'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        :perPage="[10, 25, 50, 100]"
                        :showAdvancedFilters="false"
                    >
                        <tbody slot="body" slot-scope="{data}" >
                            <tr :key="item.id" v-for="item in data">
                                <td>
                                    <div class="tblPrincipal">{{ item.remitente_usuario_nombre }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ item.titulo }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal d-flex justify-center">{{ dateFormat(item.fecha_creacion) }}</div>
                                </td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-menu v-model="item.menu" bottom :close-on-content-click="false" class="elevation-0 d-inline" content-class="mt-0 menuClassAccionesVer menu-width ">
                                            <template v-slot:activator="{on, attrs}" >
                                                <v-btn 
                                                v-on="on"
                                                color="transparent"
                                                rounded
                                                :ripple="false"
                                                class="botonHover elevation-0" 
                                                @click="abrirColaboradores(item.colaboradores)"
                                                icon fab dark small>
                                                    <v-icon class="tamIconoBoton invertirColorBotones" >visibility</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-row
                                                class="ma-0 d-flex justify-end"
                                                >
                                                    <v-btn
                                                        @click="item.menu = false"
                                                        small
                                                        icon
                                                        text
                                                        :ripple="false" 
                                                        class="v-btn-cerrar mr-2"
                                                    >
                                                        <v-icon class="icono-cerrar"></v-icon>
                                                    </v-btn>
                                                </v-row>
                                                <v-row class="ma-0 py-2 px-2">
                                                    <v-text-field
                                                        v-model="searchTerm"
                                                        label="Buscar..."
                                                        outlined
                                                        append-icon="mdi-magnify"
                                                    ></v-text-field>
                                                </v-row>
                                                <v-list-item  v-for="col in filteredColaboradores">
                                                        <v-row class="pa-0 ma-0" >
                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                <p class="informacionGeneralText">{{col.nombre}}</p>
                                                            </v-col>
                                                        </v-row>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" @click="descargarTxt(item.id)" class="botonHover" icon fab dark small>
                                                    <v-icon class="tamIconoBoton invertirColorBotones">cloud_download</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Exportar colaboradores</span>
                                        </v-tooltip>
                                        
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" @click="descargarZip(item)" class="botonHover" icon fab dark small>
                                                    <v-icon class="tamIconoBoton invertirColorBotones">download</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Descargar</span>
                                        </v-tooltip>
                                        
                                        <template v-if="usuario_id == item.remitente_usuario_id">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" @click="deleteZip(item.id)" class="botonHover" icon fab dark small>
                                                        <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Eliminar</span>
                                            </v-tooltip>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>   
    </div>
</template>

<script>
import contratosApi from "@/api/contratos"
import Datatable from "@/components/datatable/Datatable"

import Notify from "@/plugins/notify"
import GetUrlImg from "@/plugins/Urlimagen";

    export default {
        components:{
            'data-table': Datatable
        },        
        data(){
            return{
                datosLogin: null,
                rol: '',
                overlay: false,
                fraseOverlay: 'Descargando',
                usuario_id: null,
                filters:{
                    sistema: 'nominas',
                    order: 'desc',
                    order_by: 'fecha',
                    activo: true
                },
                url: "contratos/contratos-descargas",
                columns: [
                    {
                        label: "Responsable",
                        name: "responsable",
                        filterable: false
                    },
                    {
                        label: "Nombre",
                        name: "nombre",
                        filterable: false
                    },
                    {
                        label: "Fecha",
                        name: "fecha",
                        filterable: false,
                        align: "center"
                    },
                    {
                        label: "Colaboradores",
                        name: "colaboradores",
                        filterable: false,
                        align: "center"
                    },
                    {
                        label: "Opciones",
                        name: "opciones",
                        align: "center",
                        filterable: false
                    }
                ],
                urlImg: null,
                colaboradores: [],
                searchTerm: ''
                
            }
        },
        computed:{
            filteredColaboradores(){
                return this.colaboradores.filter((col) => col.nombre.toLowerCase().includes(this.searchTerm.toLowerCase()))
            }
        },
        methods: {
            
            init(){
                this.datosLogin = this.$session.get("usuario")
                this.rol = this.datosLogin.rol.name
                this.usuario_id = this.datosLogin.id

                this.urlImg = GetUrlImg.getURL()

            },
            descargarZip(item){
                console.log(item)
                this.overlay = true
                let archivo = item.url

                const FileDownload = require("js-file-download")
                const axios = require("axios")
                let url = "download"
                let parametros = {url: archivo}

                let nombreArchivo = ""
                let nombre_archivo = ""

                if(archivo){
                    nombreArchivo = archivo.split("/")
                    nombre_archivo = nombreArchivo[2]
                }

                try {
                    axios({
                        method: "post",
                        url: url,
                        responseType: "blob",
                        data: parametros,
                    }).then((response) => {
                        FileDownload(response.data, nombre_archivo);
                        this.overlay = false
                    });
                } catch (e) {
                    Notify.ErrorToast("Se produjo un error al realizar la descarga.");
                    this.overlay = false
                    console.log(e);
                }
        },
            descargarTxt(id){
                this.overlay = true

                const FileDownload = require("js-file-download")
                const axios = require("axios")
                let url = `contratos/contratos-descargas-txt/${id}`

                let nombreArchivo = "Colaboradores.txt"

                try {
                    axios({
                        method: "get",
                        url: url,
                        responseType: "blob",
                    }).then((response) => {
                        if(response.status == 200){
                            FileDownload(response.data, nombreArchivo);
                            this.overlay = false
                        }else if(response.status == 403){
                            Notify.ErrorToast(response.data.error)
                            this.overlay = false
                        }
                    });
                } catch (e) {
                    //Notify.ErrorToast("Se produjo un error al realizar la descarga.");
                    this.overlay = false
                    console.log(e);
                }
        },
        deleteZip(id){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el zip de contratos?",
                "Eliminar",
                () => {
                    contratosApi.eliminarZipContratos(id)
                    .then((result) => {
                        this.$refs.tablaDescargas.getData()
                        Notify.Success("Listo","El zip se eliminó correctamente");
                    })
                    .catch((err) => {
                        Notify.ErrorToast(err);
                    });   
                }
            );
        },
        dateFormat(fecha) {
            if(fecha == null){
                return;
            }
            let fecha_hora = fecha.split(" ");
            
            let fecha2 = fecha_hora[0].split("-")
          
            return `${fecha2[0]}/${fecha2[1]}/${fecha2[2]} - ${fecha_hora[1]} ${fecha_hora[2]}`;
            
        },
        abrirColaboradores(data){
            console.log('click')
            this.colaboradores = data
            console.log(this.colaboradores)
        }
        },
        created() {
            this.init()
        },
    }
</script>

<style scoped >
 .informacionGeneralSubtitle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #828282;
}
.informacionGeneralText{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #4F4F4F;
}
.menuClassAccionesVer{
    border-radius: 10px!important;
    max-height: 500px !important;
    
}
.menu-width{
    max-width: 500px;
    min-width: 450px;
    border-radius: 20px !important;
}
</style>